.banner{
    /* height: 40vh; */
}

.banner > img {
    /* height: 625px; */
}

.card-banner {
    background-color: #f2f2f2b9;
    font-size: 15px;
    padding: 14px 22px;
    position: absolute;
    bottom: 16%;
    text-align: start;
    margin: auto 80px;
    border-radius: 10px;
    max-width: 800px;
}

.carousel .slide img {
    object-fit: cover;
}

.title-highlight{
    display: block;
    font-weight: 600;
    font-size: 36px;
}

.label-highlight{
    display: block;
    font-size: 24px;
    padding: 10px 0;
}


@media screen and (min-width: 320px) and (max-width: 640px) {
    .card-banner {
        margin: auto 14px;
        padding: 8px;
        width: 90%;
    }

    .card-banner > .title-highlight{
        font-size: 16px;
    }

    .card-banner > .label-highlight {
        font-size: 12px;
    }

    .card-banner > a > .readmore {
        font-size: 10px;
    }

    .carousel .slide img {
        height: 100%;
    }
}