.main-style {
    background-color: #313131;
    color: #fff;
    padding-top: 24px;
    padding-left: 30px;
    padding-bottom: 10px;
    padding-right: 30px;
}

.content {
    margin: 60px 80px;
}

.main-content{
    margin-top: 10px;
}

.main-content > span {
    font-size: 32px;
    font-weight: 600;
}

.main-content > ul {
    list-style: none;
}

.main-content > ul > li {
    padding: 10px 0;
    font-size: 24px;
    display: flex;
}

.main-content > ul > li > a {
    text-decoration: none;
    color: #fff;
}

.path{
    float: left;
    border-left: 1px solid #fff;
    height: 200px;
    margin-right: 18px;
}

.img-content {
    display: flex;
    justify-content: center;
}

.img-content > img{
    width: 240px;
}

@media screen and (min-width: 320px) and (max-width: 640px) {
    .content{
        margin: 60px 20px;
    }

    .content > .css-10mzr36-MuiGrid-root {
        flex-direction: column;
    }

    .content > .css-10mzr36-MuiGrid-root > .MuiGrid-item {
        
    }

    .main-content {
        width: 86vw;
    }

    .main-content > ul {
        margin-left: -40px;
        width: 90%;
    }

    .main-content > ul > li {
        font-size: 18px;
    }

    .img-content {
        width: 80vw;
    }
}