.header {
    background-color: #282c34;
    min-height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: calc(10px + 2vmin);
    color: white;
}

.navigations{
  background-color: #fff;
  min-height: 80px;
  display: flex;
  flex-direction: row;
  font-size: calc(10px + 2vmin);
  color: white;
  align-items: center;
  position: relative;
}

.listing-navigation > a {
  font-size: calc(10px + 2vmin);
  color: #000;
  text-decoration: none;
  margin-right: 20px;
  font-size: 18px;
  font-weight: 600;
  padding: 10px;
}

.navigations > .image-navbar {
  margin: 0 40px 0 80px;
}

.navigations > .main-navbar {
  margin-right: 120px;
}

.navigations > .main-navbar > a {
  font-size: calc(10px + 2vmin);
  color: #000;
  text-decoration: none;
  margin-right: 20px;
  font-size: 18px;
  font-weight: 600;
  padding: 10px;
}

.navigations > .main-navbar > a:hover{
  background-color: rgba(242, 242, 242, 0.693);
  padding: 10px;
  border-radius: 12px;
}

.main-navbar{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  font-size: calc(10px + 2vmin);
  color: white;
}

.live-navbar{
  margin-right: 6vw;
  margin-left: auto;
  padding: 14px 0 10px 10vw;
  transform: translateX(100px);
}

.list-nav{
    list-style-type: none;
    margin: 0 90px;
    padding: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.list-nav > li {
    float: left;
    margin-left: 12px;
    font-size: 12px;
    letter-spacing: 1.2px;
}

.navbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.navbar > a {
    text-decoration: none;
    color: #fff;
}

.search-field{
  background-color: #434343;
  align-items: flex-start;
  gap: 10px;
  padding: 4px 10px;
  border: none;
  border-radius: 4px;
  outline: none;
  color: #fff;
}

.list-right {
    float: right;
    display: flex;
    align-items: center;
}

.list-right-nav-hidden {
  float: right;
  display: flex;
  align-items: center;
}

.list-right > span {
    margin: 0 90px 0 20px;
    font-size: 12px;
    cursor: pointer;
}

.list-right-nav-hidden > span {
  margin: 0 90px 0 20px;
  font-size: 24px;
  cursor: pointer;
}

.header-2{
    background-color: #fff !important;
}

/* contoh */
* {box-sizing: border-box;}

.topnav {
  overflow: hidden;
}

.logo{
  margin: 0 20px;
}

.logo > img{
  height: 100px;
}

.ellips > img {
  height: 16px;
}

.navigations > #nav-check {
  display: none;
}

.navigations > .nav-btn {
  display: none;
}

.navigations > .nav-header {
  display: inline;
}

.navigations > .nav-header > .nav-title {
  display: inline-block;
  font-size: 22px;
  color: #fff;
  padding: 10px 10px 10px 10px;
}

.navigations > .nav-btn {
  display: none;
}

.navigations > .nav-links {
  display: inline;
  float: right;
  font-size: 18px;
}

.navigations > .nav-links > a {
  display: inline-block;
  padding: 13px 10px 13px 10px;
  text-decoration: none;
  color: #efefef;
}

.navigations > .nav-links > a:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.navigations > #nav-check {
  display: none;
}

.navigations > .main-navbar > .list-right-nav-hidden {
  display: none;
}

@media screen and (min-width: 961px) and (max-width: 1449px) {
  .navigations > .main-navbar > .live-navbar {
    margin-right: 6vw;
    margin-left: auto;
    padding: 20px 0 10px 30px;
    transform: none;
  }
}

@media screen and (min-width: 1450) and (max-width: 1620) {
  .navigations > .main-navbar > .live-navbar {
    margin-right: 6vw !important;
    margin-left: auto !important;
    padding: 14px 0 10px 10vw !important;
    transform: none !important;
  }
}

@media screen and (max-width: 600px) {
  .topnav .search-container {
    float: none;
  }
  .topnav a, .topnav input[type=text], .topnav .search-container button {
    float: none;
    display: block;
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 14px;
  }
  .topnav input[type=text] {
    border: 1px solid #ccc;  
  }
  
  .navigations > .main-navbar > .live-navbar {
    margin-left: auto;
    margin-right: auto;
    padding: 20px 0 10px 30px;
  }

}
@media screen and (min-width: 320px) and (max-width: 640px) {
  .header{
    display: block;
    flex-direction: row;
    justify-content: space-between;
    min-height: 100%;
    padding: 10px;
  }

  .list-nav {
    margin: 0px;
  }
  .list-nav > li {
    font-size: 12px;
  }

  .list-right {
    display: none;
  }

  .navigations {
    /* width: 100px; */
  }

  .main-navbar > .listing-navigation {
    padding: 10px;
    margin: 12px;
  }

  .main-navbar > .listing-navigation > a {
    padding: 10px;
    margin-right: 12px;
    color: #fff;
  }

  .live-navbar {
    transform: translateX(0px);
  }

  .navigations > .image-navbar {
    margin: 0px 6px 0 4px;
  }

  .navigations > .image-navbar > a > img {
    margin: 0px 6px 0 4px;
    width: 127px;
  }

  .navigations > .nav-btn {
    display: inline-block;
    position: absolute;
    right: 16px;
    top: 12px;
  }
  .navigations > .nav-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
  }
  .navigations > .nav-btn > label:hover,.nav  #nav-check:checked ~ .nav-btn > label {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 12px;
  }
  .navigations > .nav-btn > label > span {
    display: block;
    width: 25px;
    height: 10px;
    border-top: 3px solid rgba(0, 0, 0, 0.603);
  }
  .navigations > .main-navbar {
    position: absolute;
    display: block;
    width: 100%;
    background-color: #333;
    height: 0px;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 80px;
    left: 0px;
    z-index: 9999;
    margin-right: 0px;
  }
  .navigations > .main-navbar > a {
    display: block;
    width: 100%;
    padding: 20px 0 10px 30px;
    color: #fff;
  }

  .navigations > .main-navbar > .list-right-nav-hidden{
    display: flex;
    float: none;
    align-items: none;
    padding: 20px 0 10px 30px;
  }

  .navigations > .main-navbar > .list-right-nav-hidden > a {
    display: flex;
  }

  .navigations > .main-navbar > .live-navbar {
    margin-left: auto;
    margin-right: auto;
    padding: 20px 0 10px 30px;
  }

  .navigations > #nav-check:not(:checked) ~ .main-navbar {
    height: 0px;
  }
  .navigations > #nav-check:checked ~ .main-navbar {
    height: calc(100vh - 50px);
    overflow-y: auto;
  }
}

@media screen and (min-width: 600px) and (max-width: 960px) {
  .header{
    display: block;
    flex-direction: row;
    justify-content: space-between;
    min-height: 100%;
    padding: 10px;
  }

  .list-nav {
    margin: 0px;
  }
  .list-nav > li {
    font-size: 12px;
  }

  .list-right {
    display: none;
  }

  .navigations {
    /* width: 100px; */
  }

  .main-navbar > .listing-navigation > a {
    padding: 0px;
    font-size: 14px;
    margin-right: 12px;
  }

  .navigations > .image-navbar {
    margin: 0px 6px 0 4px;
  }

  .navigations > .image-navbar > a > img {
    margin: 0px 6px 0 4px;
    width: 127px;
  }

  .navigations > .nav-btn {
    display: inline-block;
    position: absolute;
    right: 100px;
    top: 12px;
  }
  .navigations > .nav-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
  }
  .navigations > .nav-btn > label:hover,.nav  #nav-check:checked ~ .nav-btn > label {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 12px;
  }
  .navigations > .nav-btn > label > span {
    display: block;
    width: 25px;
    height: 10px;
    border-top: 3px solid rgba(0, 0, 0, 0.603);
  }
  .navigations > .main-navbar {
    position: absolute;
    display: block;
    width: 100%;
    background-color: #333;
    height: 0px;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 80px;
    left: 0px;
    z-index: 9999;
    margin-right: 0px;
  }
  .navigations > .main-navbar > a {
    display: block;
    width: 100%;
    padding: 20px 0 10px 30px;
    color: #fff;
  }

  .navigations > .main-navbar > .list-right-nav-hidden{
    display: flex;
    float: none;
    align-items: none;
    padding: 20px 0 10px 30px;
  }

  .navigations > .main-navbar > .list-right-nav-hidden > a {
    display: flex;
  }

  .navigations > .main-navbar > .live-navbar {
    margin-left: auto;
    margin-right: auto;
    padding: 20px 0 10px 30px;
  }

  .navigations > #nav-check:not(:checked) ~ .main-navbar {
    height: 0px;
  }
  .navigations > #nav-check:checked ~ .main-navbar {
    height: calc(100vh - 50px);
    overflow-y: auto;
  }
}

@media screen and (min-width: 960px) and (max-width: 1200px) {
  .listing-navigation{
    font-size: 12px;
  }

  .navigations > .main-navbar {
    margin-right: 20px;
    width: 100%;
  }
  
  .main-navbar > .listing-navigation > a {
    padding: 0px;
    font-size: 14px;
    margin-right: 12px;
  }
  
  .navigations > .main-navbar > a {
    font-size: 16px;
  }
}

@media screen and (min-width: 960px) and (max-width: 1620px) {
  .navigations{
    width: 100%;
  }

  .navigations > .image-navbar {
    margin: 0 2px 0 80px;
  }
  
  .navigations > .image-navbar > a > img{
    width: 80%;
  }

  .listing-navigation{
    font-size: 16px;
  }

  .listing-navigation > a {
    margin-right: 10px;
    padding: 2px;
    font-size: 16px;
    display: block;
  }

  .navigations > .main-navbar {
    margin-right: 20px;
    width: 76%;
  }
}

@media screen and (min-width: 1200px) {
  .listing-navigation{
    font-size: 16px;
  }
}