.highlight{
    background-color: #000;
    padding: 30px;
    color: #fff;
}
.captions{
    font-weight: 600;
    font-size: 20px;
}

.for-highlight > .carousel-root > .carousel.carousel-slider > ul.control-dots {
  display: none !important;
}

.carousel-status{
    display: none !important;
}

.highlight > .carousel-root > .carousel .carousel-slider > button.control-arrow button.control-prev {
    font-size: 0px !important;
}

.dataNotFound{
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    display: block;
}

.for-highlight {
  display: flex;
  align-items: center;
}

.list-highlight{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

@media screen and (min-width: 320px) and (max-width: 640px) {
  .highlight {
    position: relative;
  }

  .for-highlight{
    width: 90%;
  }

  .list-highlight{
    width: 30vh;
    margin: 0 auto;
  }

  .captions{
    font-size: 12px;
  }

  .carousel-root{
    width: 100%;
  }
}