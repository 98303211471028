.container-live {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 50%; /* 1:1 Aspect Ratio */
}

.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 1440px; 
    height: 780px;
    border: none;
}

/* responsive */

@media screen and (min-width: 320px) and (max-width: 640px) {
    .responsive-iframe {
        width: 100%;
        height: 100%;
    }
}