body {
  margin: 0;
}

a {
  text-decoration: none;
  color: #fff;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.sections{
  margin: 0 auto;
}

.App-header {
  background-color: #282c34;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-header-2 {
  background-color: #ffff;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
}

.App-link {
  color: #61dafb;
}

button{
  cursor: pointer;
}

/* header{
  padding: 30px;
  background-color: lime;
  display: flex;
  justify-content: center;
  top: 0;
  position: fixed;
  width: 100%;
} */

.visible {
  /* display: flex; */
  top: 0;
  transition: top 0.4s ease-out;
}

.hidden {
  /* display: none; */
  top: -80px;
  transition: top 0.4s ease-out;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
