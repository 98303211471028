

.footer-content > img {
    width: 38%;
    margin: 14px 0 0 0;
}

.footer{
    background-color: #313131;
    color: #fff;
}

.footer > .wrap-footers {
    display: flex;
    justify-content: space-between;
    padding: 46px 0px;
    margin: auto 80px;
}

.footer > .wrap-footers > .footer-content {
    max-width: 28%;
    text-align: start;
}

.footer > .wrap-footers > .footer-content-right{
    max-width: 32%;
    text-align: end;
    margin-top: 36px;
}

.footer > .wrap-footers > .footer-content > span {
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    text-transform: none;
}

.footer > .wrap-footers > .footer-content-right > span {
    font-size: 16px;
    line-height: 36px;
    text-transform: none;
    display: block;
}

.footer > .wrap-footers > .footer-content > p {
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: left;
    text-transform: none;
}

.footer > .wrap-footers > .footer-content-right > span > a {
    text-decoration: none;
    color: #fff;
}

hr.new1 {
    border: 0.5px solid red;
}

.footer > .copyright {
    display: flex;
    justify-content: space-between;
    padding: 0 0 10px 0;
    width: 90vw;
    margin: 0 auto;
}

.footer > .copyright > span {
    font-size: 14px;
}

@media screen and (min-width: 320px) and (max-width: 640px) {
    .footer > .wrap-footers {
        display: flex;
        padding: 46px 0px;
        margin: auto 80px;
        flex-direction: column;
    }
    .footer > .wrap-footers > .footer-content {
        max-width: 100%;
        text-align: center;
    }
    .footer > .wrap-footers > .footer-content-right {
        max-width: 100%;
        text-align: center;
    }
    .footer-content > img {
        width: 70%;
    }

    .footer > .copyright > span {
        font-size: 12px;
    }
}