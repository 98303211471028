.css-7iisfl-MuiImageList-root {
    display: grid;
    overflow-y: none !important;
    list-style: none;
    padding: 0;
    -webkit-overflow-scrolling: touch;
    width: 1635px !important;
    height: 750px !important;
}

.wrapp > .labels-gall {
    display: block;
    color: #000;
    text-transform: uppercase;
    font-weight: 600;
    text-align: left;
    padding: 16px 0 0 0;
    margin-left: 80px;
    font-size: 40px;
}

.wrap-gallery-pages {
    display: flex;
    justify-content: center;
}

.images-gallery{
    margin: 2px;
}

.gallery-list{
    display:inline;
}

.gallery img {
    width: 100%;
    height: auto;
}

.desc {
    padding: 15px;
    text-align: center;
}

.responsive {
    padding: 0 6px;
    float: left;
    width: 24.99999%;
}


.clearfix:after {
    content: "";
    display: table;
    clear: both;
}


.image-grid {
    --gap: 16px;
    --num-cols: 4;
    --row-height: 300px;

    box-sizing: border-box;
    padding: var(--gap);

    display: grid;
    grid-template-columns: repeat(var(--num-cols), 1fr);
    grid-auto-rows: var(--row-height);
    gap: var(--gap);
}

.image-grid-hide {
    --gap: 16px;
    --num-cols: 6;
    --row-height: 300px;

    box-sizing: border-box;
    padding: var(--gap);

    display: grid;
    grid-template-columns: repeat(var(--num-cols), 1fr);
    grid-auto-rows: var(--row-height);
    gap: var(--gap);
}

.image-grid > img,
.image-grid-hide > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
}

.image-grid-col-2 {
    grid-column: span 2;
}

.image-grid-row-2 {
    grid-row: span 2;
}

.modal-gallery{
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
}

.modal-gallery > .box-modal {
    width: 900px;
    outline: none;
    padding: 4px;
}

.box-modal > .text-modal {
    color: #fff;
    text-align: center;
    padding: 12px;
}

.fade-img {
    outline: none;
    display: block;
    max-width: 80%;
    max-height: 70%;
    margin: 0 auto;
}


  /* Anything udner 1024px */
@media screen and (max-width: 1024px) {
    .image-grid,
    .image-grid-hide {
        --num-cols: 2;
        --row-height: 200px;
    }
}

@media only screen and (max-width: 700px) {
    .responsive {
        width: 49.99999%;
        margin: 6px 0;
    }
}

@media only screen and (max-width: 500px) {
    .responsive {
        width: 100%;
    }
}

@media screen and (min-width: 320px) and (max-width: 640px) {
    .figure {
        width: 100% !important;
    }
    
    .fade-img {
        width: 290vw;
        height: 290vw;
        margin: 0 auto;
    }

    .box-modal {
        height: 50%;
    }

    .box-modal > .text-modal{
        font-size: 12px;
        line-height: 1.4;
        padding: 20px 10px;
    }
}

/* example */

.gallery-list {
    --anim-time--hi: 266ms;
    --anim-time--med: 400ms;
    --anim-time--lo: 600ms;

    display: flex;
    place-content: center;
    max-width: clamp(50rem, 95%, 10rem);
    width: max(22.5rem, 100%);
    min-height: 60vh;
    margin-inline: auto;
    padding: clamp(0px, (30rem  - 100vw) * 9999, 1rem);
   
}

.gallery__content--flow {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.gallery__content--flow > *  {
    flex-grow: 1;
    flex-basis: calc((30rem - 100%) * 999);
}

.figure {
    display: flex;
    position: relative;
    border-radius: .35rem;
    box-shadow:
    rgb(40, 40, 40, 0.1) 0px 2px 3px,
    rgb(20, 20, 20, 0.2) 0px 5px 8px,
    rgb(0, 0, 0, 0.25) 0px 10px 12px;
    overflow: hidden;
    transition: transform var(--anim-time--med) ease;
    margin: 12px 2px;
    text-align: center;
    color: white;
    max-height: 90vh;
    width: 26vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
}

.figure::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
        to top,
        hsla(0, 0%, 0%, 0.8) 0%,
        hsla(0, 0%, 0%, 0.7) 12%,
        hsla(0, 0%, 0%, 0.2) 41.6%,
        hsla(0, 0%, 0%, 0.125) 50%,
        hsla(0, 0%, 0%, 0.01) 59.9%,
        hsla(0, 0%, 0%, 0) 100%
    );
    opacity: 0;
    transition-property: opacity, transform;
    transition-duration: var(--anim-time--med), var(--anim-time--med);
    transition-timing-function: ease, ease;
      z-index: 4;
}

.header__caption {
    z-index: 4;
    position: absolute;
    display: inline-flex;
    flex-direction: column;
    align-self: flex-end;
    width: 100%;
    gap: 0.5rem;
    padding: 1rem;
    justify-content: center;
    text-align: center;
    transform: translateY(100%);
    transition: transform var(--anim-time--hi) linear,
    opacity var(--anim-time--hi) linear;
}

.figure:hover::before {
    opacity: 0.8;
}

.figure:hover .header__caption {
    transform: translateY(0);
    opacity: 1;
}

.figure:hover img{
    transform: scale(1);
}
.title {
    color: #fff;
}

.title--primary {
    font-size: 14px;
    font-weight: bold;
}

.title--secondary {
    font-size: 14px;
    text-transform:uppercase;
    font-weight: bold;
}

.figure > img {
    display: block;
    width: 100%;
    object-fit: cover;
    object-position: center;
    height: 100%;
    transform: scale(1.15);
    aspect-ratio: 16 / 13;
    transition: 400ms ease-in-out;
}


.MuiBackdrop-root{
    background-color: rgba(0, 0, 0, 0.8) !important;
}