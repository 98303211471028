.search-pages{
    background-color: #313131;
    color: #fff;
    padding-top: 24px;
    /* padding-left: 30px; */
    padding-bottom: 10px;
    /* padding-right: 30px; */
}

.sub-labels{
    display: block;
    color: rgba(255, 255, 255, 0.5);
    text-transform: uppercase;
    font-weight: 600;
    text-align: left;
    padding: 12px 0 0 0;
    margin-left: 80px;
    font-size: 20px;
}

.sub-categ{
    margin: 40px 0;
}

.search-race{
    margin: 0 80px;
}

.list-search-event {
    margin: 0px 100px 0 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 20px;
}


/* responsive */

@media screen and (min-width: 320px) and (max-width: 640px) {
    .sub-labels {
        font-size: 14px;
        margin-left: 10px;
    }

    .labels {
        font-size: 20px;
    }

    .list-search-event{
        margin: 28px 0 0 0;
        display: flex;
        justify-content: space-evenly;
        padding: 40px 20px;
        flex-direction: column;
    }
}